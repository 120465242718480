import { region, comuna } from "../constants/RegionesComunas";
import {
  departamento,
  provincia,
  distrito,
} from "../constants/departamentoProvinciaDistrito";
import {
  CHILE_ID,
  PERU_ID,
  COLOMBIA_ID,
  URUGUAY_ID,
} from "../constants/countryIdentity";
import { brandChile, brandPeru, brandColombia, brandUruguay } from "../constants/brand";

const listCountry = [
  {
    id: CHILE_ID,
    name: "Chile",
    value: "chile",
    imageLogin: require("../images/country/chile-login.png"),
    imageUser: require("../images/country/chile-user.png"),
    currencySymbol: "$(amount)", //(amount) se reemplaza por el monto a mostrar
    decimalCharacter: "",
    milesCharacter: ".",
    numDecimal: 0,
    listAddressOne: region,
    listAddressTwo: comuna,
    listAddressThree: [],
    labelAddressOne: "Región",
    labelAddressTwo: "Comuna",
    labelAddressThree: "",
    statusServerBackendFooter: {
      backend: true,
      correosChile: true,
      //blueExpress: true,
      informesBackend: true,
      manifiestosBackend: true,
      divisionPedidos: true,
      promociones: true,
      chileExpress: true,
      starken: true,
    },
    blockedPageList: ["operador-logistico","macro-rule-cd","logistica-inversa"],
    blockedRouteList: ["/operador-logistico","/macro-rule-cd","/logistica-inversa"],
    isPendingPayment: false,
    isClickAndColletCD: true,
    isMarketPlaceForCountry: true,
    utcConfig: "America/Santiago",
    brands: brandChile,
    canUpdateStoreLogisticOperator: false,
    canViewClientRUTOrderDetail:true,
    canDiscountChain: false,
    canPackagingCharge: false,
    canViewInvoices:false,
    canViewInstallments: true,
    isPaymentAgainstDelivery: false,
    isWareHouse:false,
  },
  {
    id:COLOMBIA_ID,
    name:"Colombia",
    value:'colombia',
    imageLogin:require('../images/country/colombia-login.png'),
    imageUser:require('../images/country/colombia-user.png'),
    currencySymbol:"$(amount)", //(amount) se reemplaza por el monto a mostrar
    decimalCharacter:'',
    milesCharacter:'.',
    numDecimal:0,
    listAddressOne:[],
    listAddressTwo:[],
    listAddressThree:[],
    labelAddressOne:"Departamento",
    labelAddressTwo:"Municipio",
    labelAddressThree:"",
    statusServerBackendFooter:{
      backend:true,
      informesBackend:true,
      divisionPedidos:true,
      promociones:true,
    },
    blockedPageList:[
      'marketplace',
      'prioridad',
      'comunavtex',
      'mantenedor-skun',
      'alarma-promocion',
      'etiquetaManifiesto',
      'allocationMatrix'
    ],
    blockedRouteList:[
      '/marketplace',
      '/marketplace-detail',
      '/prioridad',
      '/comunavtex',
      '/mantenedor-sku',
      '/alarma-promocion',
      '/etiquetaManifiesto',
      '/matriz-asignacion'
    ],
    isPendingPayment:true,
    isClickAndColletCD:true,
    isMarketPlaceForCountry:false,
    utcConfig:'America/Bogota',
    brands:brandColombia,
    canUpdateStoreLogisticOperator: false,
    canViewClientRUTOrderDetail:false,
    canDiscountChain: true,
    canPackagingCharge: true,
    canViewInvoices:true, 
    canViewInstallments: false, 
    isPaymentAgainstDelivery: true,
    isWareHouse:true,
  },
  {
    id: PERU_ID,
    name: "Peru",
    value: "peru",
    imageLogin: require("../images/country/peru-login.png"),
    imageUser: require("../images/country/peru-user.png"),
    currencySymbol: "S/ (amount)", //(amount) se reemplaza por el monto a mostrar
    decimalCharacter: ".",
    milesCharacter: ",",
    numDecimal: 2,
    listAddressOne: departamento,
    listAddressTwo: provincia,
    listAddressThree: distrito,
    labelAddressOne: "Departamento",
    labelAddressTwo: "Provincia",
    labelAddressThree: "Distrito",
    statusServerBackendFooter: {
      backend: true,
      informesBackend: true,
      divisionPedidos: true,
      promociones: true,
    },
    blockedPageList: ["marketplace","macro-rule-cd","allocationMatrix"],
    blockedRouteList: ["/marketplace", "/marketplace-detail","/macro-rule-cd",'/matriz-asignacion'],
    isPendingPayment: true,
    isClickAndColletCD: true,
    isMarketPlaceForCountry: false,
    utcConfig: "America/Lima",
    brands: brandPeru,
    canUpdateStoreLogisticOperator: true,
    canViewClientRUTOrderDetail:false,
    canDiscountChain: true,
    canPackagingCharge: false,
    canViewInvoices:false,
    canViewInstallments: false,
    isPaymentAgainstDelivery: true,
    isWareHouse:true,
  },
  {
    id: URUGUAY_ID,
    name: "Uruguay",
    value: "uruguay",
    imageLogin: require("../images/country/uruguay-login.png"),
    imageUser: require("../images/country/uruguay-user.png"),
    currencySymbol: "$(amount)", //(amount) se reemplaza por el monto a mostrar
    decimalCharacter: "",
    milesCharacter: ".",
    numDecimal: 0,
    listAddressOne: [],
    listAddressTwo: [],
    listAddressThree: [],
    labelAddressOne: "",
    labelAddressTwo: "",
    labelAddressThree: "",
    statusServerBackendFooter: {},
    blockedPageList: [],
    blockedRouteList: [],
    isPendingPayment: false,
    isClickAndColletCD: false,
    isMarketPlaceForCountry: false,
    utcConfig: "America/Montevideo",
    brands: brandUruguay,
    canUpdateStoreLogisticOperator: false,
    canViewClientRUTOrderDetail:false,
    canDiscountChain: false,
    canPackagingCharge: false,
    canViewInvoices:false,
    canViewInstallments: false,
    isPaymentAgainstDelivery: false,
    isWareHouse:false,
  },
];

export const listDomain = [
  "https://woodstock.develop2.forus-sistemas.biz",
  "https://woodstock.prod.pe.forus-sistemas.biz",
  "https://woodstock.prod.co.forus-sistemas.biz",
  "https://woodstock.prod.ur.forus-sistemas.biz",
  "https://woodstock.test.test-forus-sistemas.biz",
  "https://woodstock.qa.pe.forus-sistemas.biz",
  "https://woodstock.qa.co.forus-sistemas.biz",
  "https://woodstock.qa.ur.forus-sistemas.biz",
]

const listUrl = [
  //Prod
  {
    id: "backoffice-dev",
    url: "https://woodstock.develop2.forus-sistemas.biz",
  },
  {
    id: "prod.pe",
    url: "https://woodstock.prod.pe.forus-sistemas.biz",
  },
  {
    id: "prod.co",
    url: "https://woodstock.prod.co.forus-sistemas.biz",
  },
  {
    id: "prod.ur",
    url: "https://woodstock.prod.ur.forus-sistemas.biz",
  },

  //QA
  {
    id: "backoffice-test",
    url: "https://woodstock.test.test-forus-sistemas.biz",
  },
  {
    id: "qa.pe",
    url: "https://woodstock.qa.pe.forus-sistemas.biz",
  },
  {
    id:"qa.co",
    url:"https://woodstock.qa.co.forus-sistemas.biz",
  },
  {
    id:"qa.ur",
    url:"https://woodstock.qa.ur.forus-sistemas.biz",
  },
];

export const getCountry = () => {
  let url = window.location.host
    ? window.location.host
    : window.location.hostname;
  let objSite = listCountry.find(
    (country) => url.indexOf(`${country.id}`) > -1
  );

  return objSite ? objSite : listCountry[0];
};

export const getUrl = () => {
  let url = window.location.host
    ? window.location.host
    : window.location.hostname;
  let objSite = listUrl.find((itemUrl) => url.indexOf(`${itemUrl.id}`) > -1);

  if (objSite) {
    return objSite;
  }

  if (url.indexOf("woodstock.backoffice-dev") > -1) {
    return listUrl[0];
  }

  return listUrl[4];
};
