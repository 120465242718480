import React from "react";
import { Redirect } from "react-router-dom";
// Layout Types
import { DefaultLayout } from "../layouts";
import PrivateLayouts from "../layouts/PrivateLayouts";


const routers = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/index-ordenes" />,
  },
  {
    path: "/user-profile-lite",
    layout: PrivateLayouts,
    component: "UserProfileLite",
    isLazy:true,
  },
  {
    path: "/list-tienda",
    layout: PrivateLayouts,
    component: "IndexTienda",
    isLazy:true,
  },
  {
    path: "/list-users",
    layout: PrivateLayouts,
    component: "IndexUsuario",
    isLazy:true,
  },
  {
    path: "/add-user",
    layout: PrivateLayouts,
    component: "IndexUsuarioAdd",
    isLazy:true,
  },
  {
    path: "/add-tienda",
    layout: PrivateLayouts,
    component: "IndexTiendaAdd",
    isLazy:true,
  },
  {
    path: "/index-cx",
    layout: PrivateLayouts,
    component: "IndexCx",
    isLazy:true,
  },
  {
    path: "/index-vendedores",
    layout: PrivateLayouts,
    component: "IndexVendedores",
    isLazy:true,
  },

  {
    path: "/index-ordenes",
    layout: PrivateLayouts,
    component: "IndexOrdenes",
    isLazy:true,
  },
  {
    path: "/order-view/:id",
    layout: PrivateLayouts,
    component: "IndexOrderView",
    isLazy:true,
  },
  {
    path: "/index-retiro",
    layout: PrivateLayouts,
    component: "IndexRetiro",
    isLazy:true,
  },
  {
    path: "/edit-tienda",
    layout: PrivateLayouts,
    component: "IndexTiendaEdit",
    isLazy:true,
  },
  {
    path: "/log-tienda",
    layout: PrivateLayouts,
    component: "IndexLogTienda",
    isLazy:true,
  },
  {
    path: "/operador-logistico",
    layout: PrivateLayouts,
    component: "IndexOperadoresLogistico",
    isLazy:true,
  },
  {
    path: "/edit-operador-logistico",
    layout: PrivateLayouts,
    component: "IndexEditOperadorLogistico",
    isLazy:true,
  },
  {
    path: "/orden-problema",
    layout: PrivateLayouts,
    component: "IndexOrdenProblema",
    isLazy:true,
  },
  {
    path: "/list-centros",
    layout: PrivateLayouts,
    component: "CentrosDistribucion",
    isLazy:true,
  },
  {
    path: "/prioridad",
    layout: PrivateLayouts,
    component: "PrioridadView",
    isLazy:true,
  },
  {
    path: "/fecha-comprometida",
    layout: PrivateLayouts,
    component: "FechaComprometidaView",
    isLazy:true,
  },
  {
    path: "/editarFechaComprometida",
    layout: PrivateLayouts,
    component: "EditFecha",
    isLazy:true,
  },
  {
    path: "/editarZona",
    layout: PrivateLayouts,
    component: "EditarZonaView",
    isLazy:true
  },
  {
    path: "/lista-negra",
    layout: PrivateLayouts,
    exact: true,
    component: "BlackListView",
    isLazy:true,
  },
  {
    path: "/lista-negra/:id",
    layout: PrivateLayouts,
    component: "DetailBlackList",
    isLazy:true,
  },
  {
    path: "/usuarios/:id",
    layout: PrivateLayouts,
    component: "EditarUsuario",
    isLazy:true,
  },
  {
    path: "/etiquetaManifiesto",
    layout: PrivateLayouts,
    component: "etiquetaManifiesto",
    isLazy:true,
  },
  {
    path: "/comunavtex",
    layout: PrivateLayouts,
    component: "comunasVtex",
    isLazy:true,
  },
  {
    path: "/add-comunas-traductor",
    layout: PrivateLayouts,
    component: "IndexComunaVtexAdd",
    isLazy:true,
  },
  {
    path: "/mantenedor-promocion",
    layout: PrivateLayouts,
    component: "mantenedorPromociones",
    isLazy:true,
  },
  {
    path: "/add-mantenedor-promociones",
    layout: PrivateLayouts,
    component: "IndexPromocionAdd",
    isLazy:true,
  },
  {
    path: "/alarma-promocion",
    layout: PrivateLayouts,
    component: "alarmaPromociones",
    isLazy:true,
  },
  {
    path: "/mantenedor-sku",
    layout: PrivateLayouts,
    component: "mantenedorSku",
    isLazy:true,
  },
  {
    path: "/add-finalizar-ordenes",
    layout: PrivateLayouts,
    component: "FinalizarOrdenes",
    isLazy:true,
  },
  {
    path: "/clientes",
    layout: PrivateLayouts,
    component: "Clientes",
    isLazy:true,
  },
  {
    path: "/cliente-view",
    layout: PrivateLayouts,
    component: "ClienteView",
    isLazy:true,
  },
  {
    path: "/editPromoNoConfigCorreo",
    layout: PrivateLayouts,
    component: "editPromoNoConfigNumEmail",
    isLazy:true,
  },
  {
    path: "/marketplace",
    layout: PrivateLayouts,
    component: "MarketPlaceList",
    isLazy:true,
  },
  {
    path: "/marketplace-detail",
    layout: PrivateLayouts,
    component: "MarketPlaceDetail",
    isLazy:true,
  },
  {
    path: "/chain-discount",
    layout: PrivateLayouts,
    component: "ChainDiscount",
    isLazy:true,
  },
  {
    path: "/chain-discount-add",
    layout: PrivateLayouts,
    component: "ChainDiscountAdd",
    isLazy:true,
  },
  {
    path: "/macro-rule-cd",
    layout: PrivateLayouts,
    component: "MacroRule",
    isLazy:true,
  },
  {
    path: "/logistica-inversa",
    layout: PrivateLayouts,
    component: "LogisticaInversa",
    isLazy:true,
  },
  {
    path: "/matriz-asignacion-edit",
    layout: PrivateLayouts,
    component: "EditAllocationMatrix",
    isLazy:true,
  },
  {
    path: "/matriz-asignacion",
    layout: PrivateLayouts,
    component: "ListAllocationMatrix",
    isLazy:true,
  },
  {
    path: "/gwp",
    layout: PrivateLayouts,
    component: "gwp",
    isLazy:true,
  },
  {
    path: "/edit-gwp/:id",
    layout: PrivateLayouts,
    component: "gwpID",
    isLazy:true,
  },
  {
    path: "/create-gwp",
    layout: PrivateLayouts,
    component: "createGWP",
    isLazy:true,
  },
];

/**
 *  URL PARA BUSCAR ICONOS
 *  https://fonts.google.com/icons?icon.size=24&icon.color=%23e3e3e3 
 */

let itemsSidebarNav = [
  {
    id: "index-ordenes",
    title: "Dashboard",
    to: "/index-ordenes",
    htmlBefore: 'dashboard',
    htmlAfter: "",
  },
  {
    id: "index-retiro",
    title: "Retiro en Tiendas",
    to: "/index-retiro",
    htmlBefore: 'widgets',
    htmlAfter: "",
  },
  {
    id: "index-cx",
    title: "CX",
    to: "/index-cx",
    htmlBefore: 'center_focus_strong',
    htmlAfter: "",
  },
  {
    id: "logistica-inversa",
    title: "Logística Inversa",
    to: "/logistica-inversa",
    htmlBefore: 'center_focus_strong',
    htmlAfter: "",
  },
  {
    id: "orden-problema",
    title: "Ordenes con Problemas",
    to: "/orden-problema",
    htmlBefore: 'error',
    htmlAfter: "",
  },
  {
    id: "usuarios",
    title: "Usuarios",
    to: "",
    htmlBefore: 'person_pin',
    child: true,
    childs: [
      {
        id: "list-users",
        title: "Usuarios de Backoffice",
        to: "/list-users",
        parent: "usuarios",
      },
      {
        id: "index-vendedores",
        title: "Vendedores",
        to: "/index-vendedores",
        parent: "usuarios",
      },
    ],
  },
  {
    id: "tiendas",
    title: "Tiendas",
    htmlBefore: 'shop',
    to: "",
    child: true,
    childs: [
      {
        id: "list-tienda",
        title: "Tiendas",
        to: "/list-tienda",
        parent: "tiendas",
      },
      {
        id: "log-tienda",
        title: "Log Tienda",
        to: "/log-tienda",
        parent: "tiendas",
      },
      {
        id: "operador-logistico",
        title: "Operador Logístico",
        to: "/operador-logistico",
        parent: "tiendas",
      }
    ],
  },
  {
    id: "lista-negra",
    title: "Division Técnica",
    htmlBefore: 'list',
    to: "/lista-negra",
  },
  {
    id: "vtex",
    title: "VTEX",
    htmlBefore: 'add_shopping_cart',
    to: "",
    child: true,
    childs: [
      {
        id: "comunavtex",
        title: "Comunas Vtex",
        htmlBefore: 'location_city',
        to: "/comunavtex",
        parent: "vtex",
      },
      {
        id: "mantenedor-promocion",
        title: "Mantenedor Promociones",
        htmlBefore: 'local_offer',
        to: "/mantenedor-promocion",
        parent: "vtex",
      },
      {
        id: "mantenedor-skun",
        title: "Mantenedor SKU",
        htmlBefore: 'local_offer',
        to: "/mantenedor-sku",
        parent: "vtex",
      },
      {
        id: "alarma-promocion",
        title: "Alarma Promociones",
        htmlBefore: 'local_offer',
        to: "/alarma-promocion",
        parent: "vtex",
      },
    ],
  },
  {
    id: "list-centros",
    title: "Centros de Distribución",
    htmlBefore: 'input',
    to: "/list-centros",
  },
  {
    id: "allocationMatrix",
    title: "Matriz Asignación",
    htmlBefore: 'local_shipping',
    to: "/matriz-asignacion",
  },
  {
    id: "prioridad",
    title: "Gestión de Prioridad",
    htmlBefore: 'input',
    to: "/prioridad",
  },
  {
    id: "macro-rule-cd",
    title: "Macro Regla CD",
    htmlBefore: 'input',
    to: "/macro-rule-cd",
  },
  {
    id: "etiquetaManifiesto",
    title: "Corrección Incidentes",
    htmlBefore: 'add_alert',
    to: "/etiquetaManifiesto",
  },
  {
    id: "clientes",
    title: "Clientes",
    htmlBefore: 'group',
    to: "/clientes",
  },
  {
    id: "marketplace",
    title: "Marketplace",
    htmlBefore: 'storefront',
    to: "/marketplace",
  },
  {
    id: "gwp",
    title: "Mantenedor GWP",
    htmlBefore: 'paid',
    to: "/gwp",
  },
];

const profiles = {
  ADMINISTRADOR: {
    routes: ["ADMINISTRADOR"],
    sidebar: ["ADMINISTRADOR"],
  },
  VENDEDOR: {
    routes: [
      "/", //Obligatorio
      "/index-ordenes",
      "/order-view/:id",
      "/etiquetaManifiesto",
      "/orden-problema",
      "/user-profile-lite",
    ],
    sidebar: ["index-ordenes", "etiquetaManifiesto", "orden-problema"],
  },
  JEFEDETIENDA: {
    routes: [
      "/", //Obligatorio
      "/index-ordenes",
      "/order-view/:id",
      "/etiquetaManifiesto",
      "/orden-problema",
      "/user-profile-lite",
    ],
    sidebar: ["index-ordenes", "etiquetaManifiesto", "orden-problema"],
  },
  MONITOR: {
    routes: [
      "/", //Obligatorio
      "/index-ordenes",
      "/index-retiro",
      "/add-finalizar-ordenes",
      "/order-view/:id",
    ],
    sidebar: ["index-ordenes","index-retiro"],
  },
};

export { profiles, routers, itemsSidebarNav };
